import React from 'react';
// import Init from './tests/init';
// import TestToo from './tests/testToo';
// import TestTree from './tests/testTree';
// import Movers from './tests/2-movers';
// import Disconnect from './tests/3-disconnect';
// import Iconic from './tests/4-iconic';
// import Sidebar from './tests/5-sidebar';
import StatusColors from './tests/6-statusColors';

export default function App() {
  return (
    <div className="w-screen h-screen">
      <StatusColors />
    </div>
  );
}
