import React, { useState, useCallback, useEffect, useRef } from 'react';
import ReactFlow, { ReactFlowProvider, addEdge, useNodesState, useEdgesState, Handle, Position, Background, useReactFlow } from 'reactflow';
import 'reactflow/dist/style.css';

const EDGE_STROKE_WIDTH = {
  DEFAULT: 1.5,
  COMPLETED: 2,
  SELECTED: 3,
};

const EDGE_OPTIONS = {
  // animated: true,
  // style: {
  //   stroke: "blue",
  // },
};

const CONNECTION_LINE_STYLE = { stroke: 'white' };

const statusColors = (status) => {
  const colors = {
    completed: 'bg-blue-500 text-white border-blue-500',
    next: 'bg-blue-500 text-white border-blue-500',
    upcoming: 'bg-white border-slate-300 text-slate-300',
    default: 'bg-white',
  };
  return colors[status] || colors.default;
};

const borderColors = (status) => {
  const borders = {
    completed: 'border-blue-500',
    selected: 'bg-blue-500',
    hovered: 'bg-green-300',
    options: 'bg-yellow-500',
    default: 'bg-white',
  };
  return borders[status] || borders.default;
};

const edgeColors = (status) => {
  const edges = {
    completed: 'bg-blue-500 text-white border-blue-500',
    default: 'bg-white',
  };
  return edges[status] || edges.default;
};

const selectionStyle = (data) => {
  if (data.selectedNode && data.isSelected) return 'shadow-lg';
  if (data.selectedNode && !data.isSelected) return 'opacity-20';
  return '';
};

const CustomNode = ({ id, data }) => {
  const { node, menuVisible, toggleMenu, setSidebarNode } = data;
  const nodeRef = useRef(null);
  const bgColor = statusColors(data.status);
  const borderColor = borderColors(data.status);

  const handleClickOutside = useCallback(
    (event) => {
      if (nodeRef.current && !nodeRef.current.contains(event.target)) {
        toggleMenu(null);
      }
    },
    [toggleMenu]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [handleClickOutside]);

  return (
    <div ref={nodeRef} className={`px-2 py-[2px] border rounded-[6px] ${bgColor} relative cursor-pointer border-black border-2 ${selectionStyle(data)}`}>
      <div className="group/left">
        <div className="absolute h-[200%] left-0 top-0 w-7 -translate-x-1/2 -translate-y-1/4"></div>
        <Handle type="target" position={Position.Left} id="left" className="opacity-0 group-hover/left:opacity-100"></Handle>
      </div>
      <div className="group/right">
        <div className="absolute h-[200%] right-0 top-0 w-7 translate-x-1/2 -translate-y-1/4"></div>
        <Handle type="source" position={Position.Right} id="right" className="opacity-0 group-hover/right:opacity-100"></Handle>
      </div>
      <div>
        <div
          className="absolute top-0 left-0 -translate-x-1/2 -translate-y-1/2 w-5 h-5 border-2 border-black bg-black rounded-full z-40"
          onClick={(event) => {
            event.stopPropagation();
            toggleMenu(event, id);
          }}
        >
          <img src={`https://api.dicebear.com/9.x/big-ears/svg?seed=${id}`} alt="Node Icon" />
        </div>
        <div className="font-medium mb-[2px] text-sm">{data.label}</div>
      </div>
      {menuVisible === id && (
        <div>
          <div className="absolute top-0 left-0 w-full h-full z-40" onClick={(event) => event.stopPropagation()}></div>
          <div className="absolute top-[-70px] right-0 w-40 text-black bg-white border border-gray-300 rounded shadow-lg p-2 z-50">
            <div
              className="p-1 hover:bg-gray-200 text-xs"
              onClick={(event) => {
                event.stopPropagation();
                setSidebarNode(node);
              }}
            >
              Options
            </div>
            <div className="p-1 hover:bg-gray-200 text-xs" onClick={(event) => toggleMenu(event, null)}>
              Close
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const NodeSidebar = ({ node, closeSidebar, updateNodeData }) => {
  const [json, setJson] = useState(JSON.stringify(node?.data || {}, null, 2));

  useEffect(() => {
    if (node) {
      setJson(JSON.stringify(node.data, null, 2));
    }
  }, [node]);

  const handleJsonChange = (event) => {
    setJson(event.target.value);
  };

  const handleUpdateClick = () => {
    try {
      const updatedData = JSON.parse(json);
      updateNodeData(node.id, updatedData);
    } catch (error) {
      alert('Invalid JSON');
    }
  };

  const handleSidebarClick = (event) => {
    event.stopPropagation();
  };

  if (!node?.id) return null;

  return (
    <div className="fixed top-0 left-0 w-64 h-full bg-white border-l border-gray-300 shadow-lg p-4 z-50" onClick={handleSidebarClick}>
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-semibold">Node ID: {node.id}</h2>
        <button onClick={closeSidebar} className="text-red-500">
          Close
        </button>
      </div>
      <div className="mt-2">
        <textarea className="w-full h-40 border p-2" value={json} onChange={handleJsonChange} />
        <button onClick={handleUpdateClick} className="mt-2 p-2 bg-blue-500 text-white rounded">
          Update Node Data
        </button>
      </div>
    </div>
  );
};

const nodeTypes = {
  custom: CustomNode,
};

const allSettings = {
  defaultNodes: [
    { id: 'id_a', type: 'custom', position: { x: 0, y: 0 }, data: { label: 'Sales Call', status: 'completed', whatever: 'else I want' } },
    { id: 'id_b', type: 'custom', position: { x: 120, y: 0 }, data: { label: 'Ads Call', status: 'completed', whatever: 'else I want' } },
    { id: 'id_c', type: 'custom', position: { x: 120, y: -40 }, data: { label: 'Tech Call', status: 'current', whatever: 'else I want' } },
    { id: 'id_d', type: 'custom', position: { x: 240, y: 0 }, data: { label: 'Contract Signed', status: 'upcoming', whatever: 'else I want' } },
    { id: 'id_e', type: 'custom', position: { x: 400, y: 0 }, data: { label: 'Hosting', status: 'upcoming', whatever: 'else I want' } },
  ],
  defaultEdges: [
    { id: '1', source: 'id_a', target: 'id_b', animated: false, style: { stroke: '#B2B2B2', strokeWidth: EDGE_STROKE_WIDTH.COMPLETED } },
    { id: '2', source: 'id_a', target: 'id_c', animated: false, style: { stroke: '#B2B2B2', strokeWidth: EDGE_STROKE_WIDTH.COMPLETED } },
    { id: '3', source: 'id_b', target: 'id_d', animated: false, style: { stroke: '#B2B2B2', strokeWidth: EDGE_STROKE_WIDTH.COMPLETED } },
    { id: '4', source: 'id_c', target: 'id_d', animated: false, style: { stroke: '#B2B2B2', strokeWidth: EDGE_STROKE_WIDTH.DEFAULT } },
    { id: '5', source: 'id_d', target: 'id_e', animated: false, style: { stroke: '#B2B2B2', strokeWidth: EDGE_STROKE_WIDTH.DEFAULT } },
  ],
};

let nodeId = 0;

const StatusColorsFlow = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState(allSettings.defaultNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(allSettings.defaultEdges);
  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedEdge, setSelectedEdge] = useState(null);
  const [menuVisible, setMenuVisible] = useState(null);
  const [sidebarNode, setSidebarNode] = useState(null);

  const flowContainerRef = useRef(null);
  const ReactFlowProvider = useReactFlow();

  const toggleMenu = useCallback((event, nodeId) => {
    event && event.stopPropagation();
    if (!nodeId) {
      setMenuVisible(null);
    } else {
      setMenuVisible((prev) => (prev === nodeId ? null : nodeId));
    }
  }, []);

  const closeSidebar = useCallback(() => {
    setSidebarNode(null);
  }, []);

  const closeAllMenus = useCallback(() => {
    setMenuVisible(null);
    setSelectedNode(undefined); // FYI: undefined for this state was the only way I could reset the xy coordinates to click where I intended. It is incredibly stupid but it works.
  }, [closeSidebar]);

  const handleClickOutside = useCallback(
    (event) => {
      if (flowContainerRef.current && !flowContainerRef.current.contains(event.target)) {
        closeAllMenus();
      }
    },
    [closeAllMenus]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [handleClickOutside]);

  const addNodeAtPosition = useCallback(
    (position) => {
      const id = `id_${++nodeId}`;
      const newNode = {
        id,
        type: 'custom',
        position,
        data: {
          label: `Node ${id}`,
          menuVisible,
          toggleMenu,
          setSidebarNode,
          selectedNode,
        },
      };
      setNodes((nds) => nds.concat(newNode));
    },
    [setNodes, menuVisible, toggleMenu, selectedNode]
  );

  const removeEdges = useCallback(() => setEdges([]), [setEdges]);

  const onNodeClick = useCallback((event, node) => {
    setSelectedNode((prev) => (prev === node.id ? null : node.id));
    event.stopPropagation();
  }, []);

  const onEdgeClick = useCallback(
    (event, edge) => {
      setEdges((eds) =>
        eds.map((e) =>
          e.id === edge.id
            ? { ...e, style: { ...e.style, strokeWidth: selectedEdge?.id === edge.id ? EDGE_STROKE_WIDTH.DEFAULT : EDGE_STROKE_WIDTH.SELECTED } }
            : { ...e, style: { ...e.style, strokeWidth: EDGE_STROKE_WIDTH.DEFAULT } }
        )
      );
      setSelectedEdge((prev) => (prev?.id === edge.id ? null : edge));
      event.stopPropagation();
    },
    [setEdges, selectedEdge]
  );

  const removeSelectedNode = useCallback(() => {
    if (selectedNode) {
      setNodes((nds) => nds.filter((n) => n.id !== selectedNode));
      setEdges((eds) => eds.filter((e) => e.source !== selectedNode && e.target !== selectedNode));
      setSelectedNode(undefined);
    }
  }, [selectedNode, setNodes, setEdges]);

  const removeSelectedEdge = useCallback(() => {
    if (selectedEdge) {
      setEdges((eds) => eds.filter((e) => e.id !== selectedEdge.id));
      setSelectedEdge(null);
    }
  }, [selectedEdge, setEdges]);

  const handleOnDoubleClickCapture = useCallback(
    (event) => {
      const position = ReactFlowProvider.screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });

      addNodeAtPosition({ x: position.x, y: position.y });
    },
    [addNodeAtPosition]
  );

  useEffect(() => {
    const flowContainer = flowContainerRef.current;

    if (flowContainer) {
      flowContainer.addEventListener('dblclick', handleOnDoubleClickCapture);
    }

    return () => {
      if (flowContainer) {
        flowContainer.removeEventListener('dblclick', handleOnDoubleClickCapture);
      }
    };
  }, [handleOnDoubleClickCapture]);

  const updateNodeData = useCallback(
    (id, data) => {
      setNodes((nds) => nds.map((node) => (node.id === id ? { ...node, data } : node)));
    },
    [setNodes]
  );

  return (
    <>
      <ReactFlow
        nodes={nodes.map((node) => ({
          ...node,
          data: { node, ...node.data, isSelected: node.id === selectedNode, menuVisible, toggleMenu, setSidebarNode, selectedNode },
        }))}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={(params) => setEdges((eds) => addEdge(params, eds))}
        onNodeClick={onNodeClick}
        onEdgeClick={onEdgeClick}
        nodeTypes={nodeTypes}
        defaultEdgeOptions={EDGE_OPTIONS}
        fitView
        snapToGrid
        snapGrid={[20, 20]}
        connectionLineStyle={CONNECTION_LINE_STYLE}
        onPaneClick={closeAllMenus}
        onDoubleClickCapture={handleOnDoubleClickCapture}
        proOptions={{ hideAttribution: true }}
        ref={flowContainerRef}
      >
        <Background className="bg-slate-100 " color="#f1f5f9" gap={20} />
      </ReactFlow>
      <div className="absolute top-0 left-0 w-screen h-screen bg-transparent pointer-events-none"></div>
      <button onClick={removeEdges} className="absolute top-4 left-4 z-10 p-2 bg-red-500 text-white rounded">
        Remove All Edges
      </button>
      <NodeSidebar node={sidebarNode} closeSidebar={closeSidebar} updateNodeData={updateNodeData} />
    </>
  );
};

const App = () => {
  return (
    <ReactFlowProvider>
      <StatusColorsFlow />
    </ReactFlowProvider>
  );
};

export default App;
